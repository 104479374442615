<template>
  <div class="lottery-category">
    <el-card>
      <!-- 顶部操作栏 -->
      <div class="toolbar">
        <el-button type="primary" @click="handleAdd">新增类别</el-button>
      </div>

      <!-- PC端表格 -->
      <el-table v-if="!isMobile" :data="categoryList" style="width: 100%; margin-top: 20px;" v-loading="loading">
        <el-table-column prop="id" label="ID" width="80" align="center" />
        <el-table-column prop="name" label="类别名称" min-width="120" />
        <el-table-column prop="code" label="类别代码" min-width="120" />
        <el-table-column label="排序" width="120">
          <template slot-scope="scope">
            <el-input-number v-model="scope.row.sort" :min="0" size="small" style="width: 100px"
              controls-position="right" @change="(value) => handleSortChange(scope.row, value)"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="0"
              @change="(value) => handleStatusChange(scope.row, value)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" class="danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 移动端卡片列表 -->
      <div v-else class="mobile-list">
        <el-card v-for="item in categoryList" :key="item.id" class="mobile-item" shadow="hover">
          <div class="mobile-item-header">
            <div class="mobile-item-info">
              <div class="mobile-item-title">{{ item.name }}</div>
              <div class="mobile-item-code">代码：{{ item.code }}</div>
            </div>
          </div>
          <div class="mobile-item-body">
            <div class="mobile-item-row">
              <span class="label">排序：</span>
              <el-input-number v-model="item.sort" :min="0" style="width: 120px" size="small" controls-position="right"
                @change="(value) => handleSortChange(item, value)"></el-input-number>
            </div>
            <div class="mobile-item-row">
              <span class="label">状态：</span>
              <el-switch v-model="item.status" :active-value="1" :inactive-value="0"
                @change="(value) => handleStatusChange(item, value)"></el-switch>
            </div>
            <div class="mobile-item-row">
              <span class="label">开奖规则：</span>
              <div class="rule-preview">{{ formatDrawRule(item.drawRule) }}</div>
            </div>
          </div>
          <div class="mobile-item-footer">
            <el-button type="primary" size="mini" @click="handleEdit(item)">编辑</el-button>
            <el-button type="danger" size="mini" @click="handleDelete(item)">删除</el-button>
          </div>
        </el-card>
      </div>
    </el-card>

    <!-- 新增/编辑对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :width="isMobile ? '95%' : '500px'"
      :fullscreen="isMobile" custom-class="category-dialog" @close="resetForm">
      <el-form ref="categoryForm" :model="categoryForm" :rules="rules" :label-width="isMobile ? 'auto' : '80px'"
        :label-position="isMobile ? 'top' : 'right'" size="medium">
        <el-form-item label="类别名称" prop="name">
          <el-input v-model="categoryForm.name" placeholder="请输入类别名称" @input="handleNameInput"></el-input>
        </el-form-item>
        <el-form-item label="类别代码" prop="code">
          <el-input v-model="categoryForm.code" placeholder="请输入类别代码" :disabled="autoGenerateCode"></el-input>
          <div class="form-item-tip">
            <el-checkbox v-model="autoGenerateCode">自动生成代码</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="categoryForm.sort" :min="0" :max="999" controls-position="right"></el-input-number>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-switch v-model="categoryForm.status" :active-value="1" :inactive-value="0" active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
        <el-form-item label="开奖规则" prop="drawRule">
          <div class="quick-rules">
            <el-button type="text" size="small" @click="applyQuickRule('ssc')">时时彩</el-button>
            <el-button type="text" size="small" @click="applyQuickRule('11x5')">11选5</el-button>
            <el-button type="text" size="small" @click="applyQuickRule('k3')">快3</el-button>
            <el-button type="text" size="small" @click="applyQuickRule('pk10')">PK10</el-button>
            <el-button type="text" size="small" @click="applyQuickRule('lhc')">六合彩</el-button>
          </div>
          <el-input
            type="textarea"
            v-model="categoryForm.drawRule"
            :rows="8"
            placeholder="请输入开奖规则 JSON 配置，或点击上方快捷配置按钮"
          ></el-input>
          <div class="form-item-tip">
            <i class="el-icon-info"></i>
            配置说明：
            <ul class="rule-tips">
              <li>numberRange: 号码范围配置，包含最小值(min)和最大值(max)</li>
              <li>drawCount: 开奖号码个数</li>
              <li>allowDuplicate: 是否允许重复号码</li>
              <li>specialNumber: 特殊号码配置（如特码）
                <ul>
                  <li>enabled: 是否启用特殊号码</li>
                  <li>count: 特殊号码个数</li>
                  <li>numberRange: 特殊号码范围</li>
                  <li>allowDuplicate: 是否允许与普通号码重复</li>
                </ul>
              </li>
              <li>sortResult: 是否对开奖结果排序</li>
            </ul>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" :size="isMobile ? 'medium' : 'small'">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitting" :size="isMobile ? 'medium' : 'small'">确
          定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pinyin from 'chinese-to-pinyin'

export default {
  name: 'LotteryCategory',
  data() {
    return {
      loading: false,
      submitting: false,
      categoryList: [],
      dialogVisible: false,
      dialogType: 'add',
      autoGenerateCode: true,
      categoryForm: {
        name: '',
        code: '',
        sort: 0,
        status: 1,
        drawRule: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入类别名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入类别代码', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9_]+$/, message: '只能包含字母、数字和下划线', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入排序值', trigger: 'blur' }
        ],
        drawRule: [
          { required: false },
          { 
            validator: (rule, value, callback) => {
              if (!value) {
                callback();
                return;
              }
              try {
                JSON.parse(value);
                callback();
              } catch (error) {
                callback(new Error('请输入合法的 JSON 格式'));
              }
            }, 
            trigger: 'blur'
          }
        ]
      },
      isMobile: false
    }
  },
  computed: {
    dialogTitle() {
      return this.dialogType === 'add' ? '新增类别' : '编辑类别'
    }
  },
  created() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.fetchCategoryList()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice)
  },
  methods: {
    checkDevice() {
      this.isMobile = window.innerWidth < 768
    },
    async fetchCategoryList() {
      try {
        this.loading = true
        const res = await this.$http.get('/api/admin/lottery/categories')
        if (res.data.code === 200) {
          this.categoryList = res.data.data
        }
      } catch (error) {
        this.$message.error('获取类别列表失败')
      } finally {
        this.loading = false
      }
    },
    handleAdd() {
      this.dialogType = 'add'
      this.dialogVisible = true
      this.categoryForm = {
        name: '',
        code: '',
        sort: 0,
        status: 1,
        drawRule: JSON.stringify({
          numberRange: { min: 0, max: 9 },
          drawCount: 5,
          allowDuplicate: true,
          positions: [
            { name: "万位", index: 0 },
            { name: "千位", index: 1 },
            { name: "百位", index: 2 },
            { name: "十位", index: 3 },
            { name: "个位", index: 4 }
          ],
          sortResult: false
        }, null, 2)
      }
      this.autoGenerateCode = true
    },
    handleEdit(row) {
      this.dialogType = 'edit'
      this.categoryForm = { 
        ...row,
        drawRule: row.drawRule ? JSON.stringify(JSON.parse(row.drawRule), null, 2) : ''
      }
      this.dialogVisible = true
      this.autoGenerateCode = false
    },
    async handleDelete(row) {
      try {
        await this.$confirm('确认删除该类别吗？', '提示', this.getConfirmConfig('确认删除该类别吗？'))
        const res = await this.$http.delete(`/api/admin/lottery/categories/${row.id}`)
        if (res.data.code === 200) {
          this.$message.success('删除成功')
          this.fetchCategoryList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          const errorMessage = error.response?.data?.message || '删除失败'
          this.$message.error(errorMessage)
          console.error('删除类别失败:', error)
        }
      }
    },
    async handleStatusChange(row, value) {
      try {
        const res = await this.$http.put(`/api/admin/lottery/categories/${row.id}`, {
          status: value
        })
        if (res.data.code !== 200) {
          throw new Error('更新失败')
        }
        this.$message.success('状态更新成功')
      } catch (error) {
        row.status = value ? 0 : 1 // 恢复原状态
        this.$message.error('状态更新失败')
      }
    },
    async handleSortChange(row, value) {
      try {
        const res = await this.$http.put(`/api/admin/lottery/categories/${row.id}`, {
          sort: value
        })
        if (res.data.code !== 200) {
          throw new Error('更新失败')
        }
      } catch (error) {
        this.$message.error('更新排序失败')
        this.fetchCategoryList()
      }
    },
    resetForm() {
      this.$refs.categoryForm?.resetFields()
      this.categoryForm = {
        name: '',
        code: '',
        sort: 0,
        status: 1,
        drawRule: ''
      }
      this.autoGenerateCode = true
    },
    async submitForm() {
      try {
        await this.$refs.categoryForm.validate()

        this.submitting = true
        const isEdit = this.dialogType === 'edit'
        const url = isEdit
          ? `/api/admin/lottery/categories/${this.categoryForm.id}`
          : '/api/admin/lottery/categories'

        const method = isEdit ? 'put' : 'post'
        const res = await this.$http[method](url, this.categoryForm)

        if (res.data.code === 200) {
          this.$message.success(`${isEdit ? '编辑' : '新增'}成功`)
          this.dialogVisible = false
          this.fetchCategoryList()
        }
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error(`${this.dialogType === 'edit' ? '编辑' : '新增'}失败`)
        }
      } finally {
        this.submitting = false
      }
    },
    handleNameInput(value) {
      if (this.autoGenerateCode && value) {
        const pinyinStr = pinyin(value, { removeSpace: true, firstCharacter: true })
        this.categoryForm.code = pinyinStr.toLowerCase()
      }
    },
    getConfirmConfig(message, title = '提示') {
      return {
        title,
        message,
        customClass: 'mobile-confirm-dialog',
        center: this.isMobile,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: this.isMobile ? 'confirm-button' : '',
        cancelButtonClass: this.isMobile ? 'cancel-button' : '',
        type: 'warning'
      }
    },
    formatDrawRule(rule) {
      if (!rule) return '未设置';
      try {
        const ruleObj = typeof rule === 'string' ? JSON.parse(rule) : rule;
        return JSON.stringify(ruleObj, null, 2);
      } catch (error) {
        return '格式错误';
      }
    },
    applyQuickRule(type) {
      const rules = {
        ssc: {
          numberRange: { min: 0, max: 9 },
          drawCount: 5,
          allowDuplicate: true,
          positions: [
            { name: "万位", index: 0 },
            { name: "千位", index: 1 },
            { name: "百位", index: 2 },
            { name: "十位", index: 3 },
            { name: "个位", index: 4 }
          ],
          sortResult: false
        },
        '11x5': {
          numberRange: { min: 1, max: 11 },
          drawCount: 5,
          allowDuplicate: false,
          positions: [
            { name: "第一位", index: 0 },
            { name: "第二位", index: 1 },
            { name: "第三位", index: 2 },
            { name: "第四位", index: 3 },
            { name: "第五位", index: 4 }
          ],
          sortResult: false
        },
        k3: {
          numberRange: { min: 1, max: 6 },
          drawCount: 3,
          allowDuplicate: true,
          positions: [
            { name: "第一位", index: 0 },
            { name: "第二位", index: 1 },
            { name: "第三位", index: 2 }
          ],
          sortResult: true
        },
        pk10: {
          numberRange: { min: 1, max: 10 },
          drawCount: 10,
          allowDuplicate: false,
          positions: [
            { name: "冠军", index: 0 },
            { name: "亚军", index: 1 },
            { name: "季军", index: 2 },
            { name: "第四名", index: 3 },
            { name: "第五名", index: 4 },
            { name: "第六名", index: 5 },
            { name: "第七名", index: 6 },
            { name: "第八名", index: 7 },
            { name: "第九名", index: 8 },
            { name: "第十名", index: 9 }
          ],
          sortResult: false
        },
        lhc: {
          numberRange: { min: 1, max: 49 },
          drawCount: 6,
          allowDuplicate: false,
          specialNumber: {
            enabled: true,
            count: 1,
            numberRange: { min: 1, max: 49 },
            allowDuplicate: false
          },
          sortResult: true
        }
      };

      this.categoryForm.drawRule = JSON.stringify(rules[type], null, 2);
    }
  }
}
</script>

<style lang="scss" scoped>
.lottery-category {
  padding: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px;
  }

  .toolbar {
    margin-bottom: 20px;
  }

  .mobile-list {
    .mobile-item {
      margin-bottom: 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      .mobile-item-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 12px;

        .el-button {
          flex: 1;
          margin-left: 0;
          
          &.danger {
            color: #fff;
            background-color: #F56C6C;
            border-color: #F56C6C;
          }
          
          &:not(.danger) {
            color: #fff;
            background-color: #409EFF;
            border-color: #409EFF;
          }
        }
      }
    }
  }

  .danger {
    color: #F56C6C;
  }
}

// 移动端弹窗样式
.category-dialog {
  @media screen and (max-width: 768px) {
    .el-dialog__body {
      padding: 12px !important;
    }

    .el-form-item {
      margin-bottom: 12px;
    }

    .el-form-item__label {
      padding-bottom: 4px;
      line-height: 1.2;
    }

    .dialog-footer {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .el-button {
        flex: 1;
      }
    }
  }
}

// 暗色模式适配
@media (prefers-color-scheme: dark) {
  .mobile-item {
    .mobile-item-header {
      .mobile-item-info {
        .mobile-item-title {
          color: #fff !important;
        }

        .mobile-item-code {
          color: #909399 !important;
        }
      }
    }

    .mobile-item-body {
      .mobile-item-row {
        .label {
          color: #909399 !important;
        }
      }
    }
  }
}

.form-item-tip {
  margin-top: 8px;
  font-size: 12px;
  color: #909399;

  .el-checkbox {
    font-size: 12px;
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .form-item-tip {
    color: #909399;
  }
}

.rule-preview {
  font-family: monospace;
  white-space: pre-wrap;
  word-break: break-all;
  font-size: 12px;
  color: #666;
  background: #f5f7fa;
  padding: 8px;
  border-radius: 4px;
  margin-top: 4px;
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .rule-preview {
    background: #363636;
    color: #909399;
  }
}

.rule-tips {
  margin: 8px 0 0 0;
  padding-left: 20px;
  font-size: 12px;
  line-height: 1.5;
  
  ul {
    padding-left: 20px;
    margin: 4px 0;
  }
  
  li {
    margin-bottom: 4px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .rule-tips {
    color: #909399;
  }
}

.quick-rules {
  margin-bottom: 8px;
  
  .el-button {
    margin-right: 12px;
    padding: 4px 8px;
    
    &:last-child {
      margin-right: 0;
    }
  }
}

// 暗黑模式适配
@media (prefers-color-scheme: dark) {
  .quick-rules {
    .el-button {
      color: #409EFF;
      
      &:hover {
        color: #66b1ff;
      }
    }
  }
}
</style>